import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import Grid from '../Grid/Grid.js';
import Page from '../Page/index.js';
import QRCard from '../Card/QR/QR.js';
import TextCard from '../Card/Text/Text';
import Card from '../Card/Card';
import StockCard from '../Card/Stock/stock.js';
import {AnimatePresence, AnimateSharedLayout, motion} from 'framer-motion';
import {log} from './../../utilities/measure.js';

import {makeQRObject} from './../Card/QR/qrUtilities';
import {makeStock, makeQuestion, getDrug, getAllDrugs} from './utilities';
import { getExample, renderData, DUMMY_QUESTION_DOSE , DUMMY_STOCK_DOSE, DUMMY_QUESTION_DRIP , 
    DUMMY_STOCK_DRIP, DUMMY_QUESTION_BSA , DUMMY_STOCK_BSA, changeBackPositions, getBackIndex } from './display.js';

const instructions = [
    {id: 'instruction-1', text: 'Glue matching page numbers together, following the pattern of page 1 front to page 1 back.', title: '1. Glue the sheets together'},
    {id: 'instruction-2', text: 'Cut the cards out by following the dashed lines.', title: '2. Cut the cards out'},
    {id: 'instruction-3', text: 'Put the patient cards in a deck.', title: '3. Patients Deck'},
    {id: 'instruction-4', text: 'Lay the stock cards out face side up in a grid.', title: '4. Medical Stock'},
    {id: 'instruction-5', text: 'Draw one patient card and calculate how much medicine to give him from the available stock card.', 
    title: '5. Problem Solve'},
    {id: 'instruction-6', text: `Check your answer by taking a picture of both the patient and stock qr codes and following the links to the site, where the answer is calculated.`, 
    title: '6. Check Answers'},
]

const GameMaker = (props) => {
    const [cards, setCards] = useState(null);
    log(`GameMaker process.env.GATSBY_SITE_URL ${process.env.GATSBY_SITE_URL}props`, props);
    useEffect(()=>{
        //make stock cards, create qr object
        //make intro sheet, stock cards and qr cards
        new Promise((res, rej) => {
            res([
                ...makeStockCards(),
                ...makeQuestionCards(9),
                
            ]);
        }).then(e => {
            log(`GameMaker promise makeCards success`, e);
            setCards(e);
        }).catch(e => {
            console.error(`GameMaker promise makeCards failed`, e);
        })
    }, [props.question]);
    const makeStockCards = () => {
        let views = [];
        let qr = [];
        let stock;
        let drugs = getAllDrugs(props.question);
        drugs.map((e, i) => {
            stock = makeStock(props.question, e, props.question === 'bsa');
            log(`GameMaker stock question ${props.question}`, stock);
            views.push(getCard('StockCard', `stock-${i}`, i, stock));
            qr.push(getCard('qr', `stock-qr-${getBackIndex(i)}`, getBackIndex(i), 
              {qr: makeQRObject(`${process.env.GATSBY_SITE_URL}/answer`, stock), title: e}))
        })
        return [
            {id: 'stocks', isFront:true, cards: views},
            {id: 'stocks-qr', isFront: false, cards: qr}
        ];
    }
    const makeQuestionCards = (count) => {
        let views = [];
        let qr = [];
        let question;
        for(let i = 0; i<count; i++){
          //make stock cards
          question = makeQuestion(props.question, getDrug(props.question));
          views.push(getCard('text', `question-back-${i}`, i, 
            {text: question.text,  properties: question.properties}
          ))
          qr.push(getCard('qr', `question-qr-${getBackIndex(i)}`, getBackIndex(i), 
            {qr: makeQRObject(`${process.env.GATSBY_SITE_URL}/answer`, question), title: 'Patient'}))
        }
        return [
            {id: 'question', isFront:true, cards: views},
            {id: 'question-back', isFront: false, cards: qr}
        ];
    }
    const getCard = (layout, id, i, c) => {
        log(`GameMaker getCard layout ${layout} i ${id} c`, c);
        switch(layout){
            case 'StockCard': return <Card id={`card-${id}`} key={`card-${id}`}  className={`item-${i}`} >
                    <StockCard key={id}  id={id} text={c.text} title={c.title} img={c.img} properties={c.properties}  />
                </Card>
            case 'qr':
                return <Card id={`card-${id}`} key={`card-${id}`}  className={`item-${i}`} >
                    <QRCard key={id} id={id} qr={c.qr} title={c.title}  />
                   </Card>
            case 'text':
            default:
                return <Card id={`card-${id}`} key={`card-${id}`}  className={`item-${i}`} >
                        <TextCard key={id}  id={id} text={c.text} title={c.title} properties={c.properties}  />
                    </Card>
        }
    }
    const renderGrids = (cards) => {
        if(cards && cards.length > 0){
            //if printInstructions, then an additional page is added to the front, so +1 to page number
            let pageNumber = 1;
            if(props.printInstructions) pageNumber += 1;
            if(props.printExample) pageNumber += 1;
            return cards.map((e, i) => {
                return <Grid key={`${props.question}-${e.id}`} id={`${props.question}-${e.id}`} 
                        page={Math.floor(i/2)+pageNumber} isFront={e.isFront} className="card-sheet">
                            {e.cards}
                        </Grid>
            });

        }
    }
    const renderInstructions = () => {
        return <Page layout key={'instructions'} id={'instructions'} page={1} isFront={true} className="card-sheet">
            <h3>Instructions</h3>
            {instructions.map((e, i) => {
                return <p key={i}>{i+1}: {e.text}</p>
            })}
        </Page>
    }
    const renderExample = (examples) => {
        let pageNumber = 1;
        if(props.printInstructions) pageNumber += 1;
        let q, a = null;
        if(props.question === 'dose'){
            q = DUMMY_QUESTION_DOSE;
            a = DUMMY_STOCK_DOSE;
        }else if(props.question === 'drip'){
            q = DUMMY_QUESTION_DRIP;
            a = DUMMY_STOCK_DRIP;
        }else if(props.question === 'bsa'){
            q = DUMMY_QUESTION_BSA;
            a = DUMMY_STOCK_BSA;
        }
        return <Page layout key={'examples'} id={'examples'} page={pageNumber} isFront={true} className="card-sheet">
            <h3>Example Problem</h3>
            {renderData(a, q)}
            {getExample(props.question, q, a)}
            </Page>
    }
    return <CardsWrapper className="game-cards">
     
        <div className="cards">
            <AnimatePresence>
                {props.printInstructions && renderInstructions()}
                {props.printExample && renderExample()}
                {cards && renderGrids(cards)}
            </AnimatePresence>
        </div>
        {/* <div className="box" /> */}
    </CardsWrapper>
}

export default GameMaker;

const CardsWrapper = styled.div`
    width:100%;
    overflow-x: auto;
    overflow-y:hidden;
    .box{
        width: 200vw;
        height: 500px;
        background-color: orange;
    }


`