import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import Card from './../Card';
import {renderProperties} from '../utilities';
import {log} from './../../../utilities/measure.js';

const TextCardView = ({id, text, title, properties}) => {

    return <div key={id} id={id}>
        {title && <h3>{title}</h3>}
        {text && <p>{text}</p>}
        {properties && renderProperties(properties)}
    </div>
}

export default TextCardView;
