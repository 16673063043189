import { PageRenderer } from 'gatsby';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {log, sendError} from './../../utilities/measure.js';

const COLUMN_COUNT = 3, ROW_COUNT = 3;


const PageView = ({id, page, isFront, children}) => {
    log(`GridView page ${page}`)
    
    return <Page id={id} key={id} layout {...PAGE_ANIMATION} className="text-sheet page" 
            width={22} height={29.7} pagePadding={1.3} unit={'cm'} >
            <PageNumber><h4 className="page-text">page {page} {isFront?'front':'back'}</h4></PageNumber>
            {children}
    </Page>

}

export default PageView;

const PAGE_ANIMATION = {
    initial: {opacity: 0, x: -100, transition: { type: " Inertia", duration: 0.3, delay:0.4}},
    animate: {opacity: 1, x: 0, transition: { type: " Inertia", duration: 0.3, delay:0.4}},
    exit: {opacity: 0, x:100, transition: { type: " Inertia", duration: 0.3}}
}

const PageNumber = styled.div`
    position:absolute;
    top:40px;
    left: 50%;
    transform: translateX(-50%);
    .page-text{
        font-size: 1.1em;
    }
`
const Page = styled(motion.div)`
    position:relative;
    margin: 10px auto;
  background-color: lightgrey;
  transform-origin: 0% 0%;
  box-sizing: border-box;
  page-break-after:always; 
  @media print{
    width: ${props => {
      return `${props.width-(props.pagePadding*2)}${props.unit};`
    }};
    overflow: hidden;
  }
  width: 100%;
  max-width: ${props => {
    return `${props.width-(props.pagePadding*2)}${props.unit};`
  }};
  overflow-x:auto;
  padding-bottom: ${props => {
    return `${props.pagePadding*0.5}${props.unit};`
  }};
  padding-top: ${props => {
    return `${props.pagePadding*1.5}${props.unit};`
  }};
  padding-left: ${props => {
    return `${props.pagePadding*1.5}${props.unit};`
  }};
  padding-right: ${props => {
    return `${props.pagePadding*1.5}${props.unit};`
  }};
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: grey;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 5px;
  }
`
/**
 *   @media print {
    width: 100%;
    height:100%;
    position:absolute;
    top:0px;
    bottom:0px;
    margin: auto;
    margin-top: 0px !important;
    border: 1px solid;
 
    transform: scale(1);
  }
 */