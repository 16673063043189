import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import '../queries.js';
import Card from './../Card';
import {renderProperties} from '../utilities';
import {log} from './../../../utilities/measure.js';
import { StaticImage,GatsbyImage, getImage } from "gatsby-plugin-image"


const getImgPath = (id, data) => {
    if(data[id]){
        return data[id].childImageSharp.gatsbyImageData
    }
    return null;
}
const StockCard = ({id, border, bg, text, img, properties}) => {
    log(`Card StockCard id ${id} text ${text} img ${img} properties`, properties);
    return  <StaticQuery
                id={id} key={id}
                query={graphql`
                    query StockQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                        pill: file(relativePath: { eq: "medical/pill.png" }) {
                            ...cardImg
                        }
                        injection: file(relativePath: { eq: "medical/injection.png" }) {
                            ...cardImg
                        }
                        toxic: file(relativePath: { eq: "medical/toxic.png" }) {
                            ...cardImg
                        }
                        drip: file(relativePath: { eq: "medical/drip.png" }) {
                            ...cardImg
                        }
                    }
                `}
                render={data => {
                    log(`Card StockCard img ${img} data`, data);
                    let image = getImgPath(img, data);
                    return <div id={id} key={id} >
                                <div style={WRAPPER_STYLES}>
                                    {image && <GatsbyImage image={image} alt={'medical image'} />}
                                </div>
                                <p>{text}</p>
                                {properties && renderProperties(properties)}
                            </div>
                }}
            />
}

const WRAPPER_STYLES = {
    textAlign: 'center'
}

export default StockCard;

