import styled from 'styled-components';

export const Wrapper = styled.div`
text-align: left;
    padding-left:30px;
    .radio-item{
        pointer-events: none;
        color: ${props => props.theme.text};
        padding-left:5px;
        margin-right:30px;
        font-size: 1em;
    }
`