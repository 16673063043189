import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './radio.css';
import {Wrapper} from './radio.css.js';
import {log, sendError} from './../../utilities/measure.js';

const Radio = (props) => {
    const [active, setActive] = useState(props.active || {});//props.active || {}
    const handleChange = (ev) => {
    
        let index = ev.target.value;
        let item = props.options[index];
        let nState = {...active};
        log(`Radio handleChange index ${index} item ${nState[index]} nState`, nState);
        nState[index] = nState[index]?false:item;
        setActive(nState);
     
        /**See if there are any modifying functions for output */
        let out = convertToOutput(nState);
        if(props.out){
            out = props.out(props.id, nState);
        }
        
        props.onInput(props.id, out);
      
    }
    const convertToOutput = (selected) => {
        let ray = [];
        for(let key in selected){
            if(selected[key]){
                ray.push(selected[key]);
            }
        };
        return ray.length === 1?ray[0]:ray;
    }

    const isChecked = (e) => {
        let value = false;
        if(active[e]){
            value = true;//active[e];
        }
        log(`Radio isChecked e ${e} props.active ${props.active} value ${value} active`, active);
        return value;
    }
    return <div key={props.id} id={props.id} className={`${props.className || ''}`}>
                
                {/* {props.text && <div><Text tag="h4">{props.text}</Text></div>}   */}
              
                {props.options.map((e, i) => {
                    return <Wrapper key={i} className="radio-container">
                                <label>
                                    <input type="checkbox" value={i} name={props.id} 
                                        checked={isChecked(i)}  onChange={handleChange}/>
                                    <span className="radio-checkmark"></span>
                                    <p className="radio-item">{e}</p>
                                </label>
                            </Wrapper>
                })}
            </div>
}

Radio.propTypes = {
    options: PropTypes.array,
    active: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.bool
    ]),
    onInput: PropTypes.func,
    out: PropTypes.func,
    isMulti: PropTypes.bool
}

export default Radio;
