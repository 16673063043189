import React from 'react';
import styled from 'styled-components';
import {log} from './../../utilities/measure.js';

export const renderProperties = (properties) => {
    if(properties){
        let views = [];
        for(let key in properties){
            views.push(
            <PropertyView key={key} id={key}>
                <p className="property-label">{key}:</p>
                <p className="property-text">{properties[key]}</p>
            </PropertyView>
            )
        }
        return views;
    }
    return null;
}

const PropertyView = styled.div`
    text-align: left;
    width:100%;
    .property-label, .property-text{
        display: inline;
    }
    .property-label{
        font-weight: bold;
    }
`