import React, {useEffect} from "react"
import { graphql } from "gatsby"
import GameMaker from '../components/GameMaker/GameMaker';
import {getParamsFromUrl} from '../components/Card/QR/qrUtilities';
import {log} from './../utilities/measure.js';
import Seo from './../components/Seo/index';
import AnimatedPageWrapper from '../components/AnimatedPageWrapper/index';

import './../components/main.css';

const IndexPage = ({location, data}) => {
  useEffect(()=>{
    log(`IndexPage location`, location);
    let queryString = location.search;
    let params = getParamsFromUrl(queryString);
    log(`IndexPage queryString ${queryString} params`, params);
  }, [location])
  return  <AnimatedPageWrapper key="index" id="index-page">
            <Seo
              title={"Medication Math Print and Play Question Card Page"}
              description={"the home page of medication math, where question cards can be printed out"}
              pathname={location.pathname}
            />
            <GameMaker siteInformation={data.site.siteMetadata} />
          </AnimatedPageWrapper>
}

export default IndexPage;

export const query = graphql`
  query IndexPage{
    site {
      ...SiteInformation
    }
    file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
  }
`