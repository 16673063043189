import { PageRenderer } from 'gatsby';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getGridFraction, getGridItems} from './utilities.js';
import {motion} from 'framer-motion';
import {log} from './../../utilities/measure.js';

const COLUMN_COUNT = 3, ROW_COUNT = 3;


const GridView = ({id, page, isFront, children}) => {
    log(`GridView page ${page}`)
    
    return <Grid id={id} key={id} layout {...GRID_ANIMATION} className="card-sheet" width={22} height={29.7} pagePadding={1.3} unit={'cm'} 
                rowGap={'0'} columnGap={'0'} rowCount={ROW_COUNT} columnCount={COLUMN_COUNT}>
            <PageNumber><h4 className="page-text">page {page} {isFront?'front':'back'}</h4></PageNumber>
            {children}
    </Grid>

}

export default GridView;

const GRID_ANIMATION = {
    initial: {opacity: 0, x: -100},
    animate: {opacity: 1, x: 0},
    exit: {opacity: 0, x:100},
    transition: { type: " Inertia", duration: 0.5}
}

const PageNumber = styled.div`
    position:absolute;
    top:40px;
    left: 50%;
    transform: translateX(-50%);
    .page-text{
        font-size: 1.1em;
    }
`
const Grid = styled(motion.div)`
    position:relative;
    margin: 10px auto;
  background-color: lightgrey;
  transform-origin: 0% 0%;
  box-sizing: border-box;
  overflow: hidden;
  @media print{
    width: ${props => {
      return `${props.width-(props.pagePadding*2)}${props.unit};`
    }};
    overflow: hidden;
  }
  width: 100%;
  max-width: ${props => {
    return `${props.width-(props.pagePadding*2)}${props.unit};`
  }};
  overflow-x:auto;
  height: ${props => {
    return `${props.height-(props.pagePadding*2)}${props.unit};`
  }};
  padding-bottom: ${props => {
    return `${props.pagePadding*0.5}${props.unit};`
  }};
  padding-top: ${props => {
    return `${props.pagePadding*1.5}${props.unit};`
  }};

  display: grid;
  justify-items: stretch;
  align-items: stretch;
  grid-template-columns: ${props => {
    return getGridFraction(props.columnCount);
  }};
  grid-template-rows: ${props => {
    return getGridFraction(props.rowCount);
  }};
  ${props => getGridItems(props.columnCount, props.rowCount)}
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: grey;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 5px;
  }
`
/**
 *   @media print {
    width: 100%;
    height:100%;
    position:absolute;
    top:0px;
    bottom:0px;
    margin: auto;
    margin-top: 0px !important;
    border: 1px solid;
 
    transform: scale(1);
  }
 */