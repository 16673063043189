import React from 'react';
import styled from 'styled-components';
import {log} from './../../utilities/measure.js';

const CardView = ({children, bg, border, id, className}) => {
    return <Card id={id} key={id} className={`card ${className || ''}`} bg={bg} border={border}>
        {children}
    </Card>
}

export default CardView;

const Card = styled.div`
    width: 100%;
    height:100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items:center;
    width:100%;
    height:100%;
    background-color: white;
    border: 2px dashed black;
    box-sizing: border-box;
`