import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';
import {log} from './../../utilities/measure.js';
import GameCards from './GameCards';
import IconButton from './../IconButton/index';
import Button from './../Button/index.js';
import Radio from './../Radio/Radio.js';
import {QUESTIONS} from './display';
import Hero from '../Hero/index';
import Share from '../Share/index';
import ToastTool from '../ToastTool/index';

const PRINT_OPTIONS = ['print instruction sheet'];
const EXAMPLE_OPTIONS = ['print example sheet'];
const GameMaker = (props) => {
    const [printInstructions, setPrintInstructions] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(0);
    const [printExample, setPrintExample] = useState(false);
    const [error, setError] = useState(null);
    const [toast, setToast] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    log(`GameMaker props`, props);
    useState(()=>{
        let timeout = setTimeout(() => {
            setHasLoaded(true)
        }, 600); //cards causing animation slow down, defer
        return ()=>{
            clearTimeout(timeout)
        }
    }, [])
    const handlePrint = (id, v) => {
        log(`GameMaker print window`, window.print);
        try{
            window.print();
        }catch(e){
            setToast({id: `${Math.random()}`, text: 'The print feature was not found in this browser'});
        }
      }
    const handleInstructions = () => {
        setPrintInstructions(!printInstructions);
    }
    const handleExampleInstructions = () => {
        //TODO
        setPrintExample(!printExample);
    }
    const handleQuestionSelection = (ev) => {
        let id = ev.target.id;
        let index = parseInt(id.split('-')[1]);
        if(isNaN(index)){
            setError('invalid index selected');
            return;
        }
        log(`GameMaker handleQuestionSelection id ${id} index ${index}`);
        setSelectedQuestion(index);
    }
    const getQuestionDescription = (index) =>{
        if(index === 0){
            return <motion.div {...QuestionMotions} key={`qd-${index}`} id={`qd-${index}`} className="question-description">
                <h2>Pill and Injection Dosage</h2>
                <p><span className="label">Skills:</span> Fractions, unit conversion</p>
                <p>Answer questions about how many tablets or milliliters of stock medication to give patients</p>
            </motion.div>
        }else if(index === 1){
            return <motion.div {...QuestionMotions} key={`qd-${index}`} id={`qd-${index}`} className="question-description">
                <h2>Intravenous Fluid</h2>
                <p><span className="label">Skills:</span> Fractions, unit conversion, rates</p>
                <p>Answer questions about how much time it will take to give patients their medication by intravenous drip</p>
            </motion.div>
        }else if(index === 2){
            return <motion.div {...QuestionMotions} key={`qd-${index}`} id={`qd-${index}`} className="question-description">
                <h2>Body Surface Area</h2>
                <p><span className="label">Skills:</span> Fractions, unit conversion, rates</p>
                <p>Answer questions about how much time it will take to give patients their medication by intravenous drip</p>
            </motion.div>
        }
    }
    return <GameWrapper>

            <QuestionOptions className="game-ui">
            
            <GameHeader>
                <div className="question-options">
                    {QUESTIONS.map((e, i) => {
                        return <button key={`q-${i}`} id={`q-${i}`} className={`question-option ${i === selectedQuestion?'question-active':''}`}
                        active={`${i === selectedQuestion}`}
                        onClick={handleQuestionSelection}
                        ><span id={`q-${i}-bg`} className="button-bg" />
                        <span id={`q-${i}-content`} className="button-content">
                        {e.text}    
                        </span>
                        </button>
                    })}
                </div>
                <Share siteInformation={props.siteInformation} />
            </GameHeader>
            <AnimatePresence exitBeforeEnter>
                {getQuestionDescription(selectedQuestion)}
            </AnimatePresence>
            {error && <div className="error"><p><span className="label">Error:</span> {error}</p></div>}
            
            <div className="print-instructions">
               
                <IconButton id="printer" icon="icon-printer" onInput={handlePrint}/>
                <div className="radios">
                <Radio key="print-radio" id="print-radio" options={PRINT_OPTIONS} onInput={handleInstructions} />
                <Radio key="demo-radio" id="demo-radio" options={EXAMPLE_OPTIONS} onInput={handleExampleInstructions} />
                </div>
                <p>Please accept cookies in order to print correctly. </p>
            </div>
            
        </QuestionOptions>
        {hasLoaded && <GameCards 
            printInstructions={printInstructions} 
            printExample={printExample} 
            question={QUESTIONS[selectedQuestion].id} 
        />}
       
        <ToastTool {...toast} />
    </GameWrapper>
}

export default GameMaker;

const QuestionMotions = {
    initial: {opacity: 0, x: -100},
    animate: {opacity: 1, x:0},
    exit: {opacity: 0, x: 100},
    transition: { type: " Inertia", duration: 0.5 }
}
const GameHeader = styled.section`
    background-color: ${props => props.theme.getColor(props.theme, 'primary')}
`
const GameWrapper = styled.div`
    width:100%;
`
const QuestionOptions = styled.section`
    width: 100%;
    margin: auto;

    .heading, .subheading{
        text-align: center;
    }


    .question-options{
        border-top: 2px solid white;
        background: ${props => props.theme.getColor(props.theme, 'primaryL')};
        width:100%;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content:stretch;
        @media(min-width: 480px){
            flex-direction: row;
        }
        .question-option{
            position: relative;
            flex: 1;
            display:block;
            width:100%;
            @media(min-width: 480px){
                display: inline;
                width: auto;
            }
            outline:0;
            padding: 10px;
            border: none;
            background-color:transparent;
            margin: 0px;
        }
        .question-active{
            
        }
        .button-content{
            position: relative;
            z-index:2;
        }
        .button-bg{
            position: absolute;
            top:0px;
            left:0px;
            right:0px;
            bottom:0px;
            z-index:1;
            background-color: #ffffffff;
            transition: opacity 1s ease;
            opacity: 0;
        }
        .question-active .button-bg{
            opacity: 0.3;
        }
    }

    .print-instructions{
        padding: 0px 10px;
        text-align:center;
        justify-content: center;
        align-items: center;
        button{
            display: inline;
            height: 50px;
            vertical-align: top;
        }
        #printer{
            display:inline;
            vertical-align:top;
        }
        .radios{
            display:inline-block;
            flex-direction:column;
        }
    }
    .print{
        display: block;
        background-color: blue;
    }
    .question-description{
        padding: 10px 20px 0px 20px;
        max-width: ${props => props.theme.widthL};
        min-height: 200px;
        margin:auto;
    }
    .error{
        padding: 10px 20px 0px 20px;
        color: red;
        max-width: ${props => props.theme.widthL};
        margin:auto;
        text-align: center;
    }
`