import React, {useRef, useEffect, useState} from 'react';
import * as QRCode from 'easyqrcodejs';
import styled from 'styled-components';
import Card from './../Card';
import {log} from './../../../utilities/measure.js';

const QRView = ({id, border, bg, title, qr}) => {
    const qrRef = useRef(null);
    useEffect(()=>{
        log(`QRView qrRef`, qrRef);
        log(`QRView qrRef qr`, qr);
        // Create new QRCode Object
        if(qrRef){
            new QRCode(qrRef.current, qr);
        }
        
    }, [])
    return <QRCard key={id} id={id}>
        <h3 >{title}</h3>
        <div id="qr-code" ref={qrRef} />
    </QRCard>
}

export default QRView;

const QRCard = styled.div`
    margin: 10px;
    text-align: center;
    canvas{
        width:200px;
        height:200px;
    }
`