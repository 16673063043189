import {log} from './../../utilities/measure.js';

export const getGridFraction = (n) => {
    let r ='';
      for(let i = 0; i<n; i++){
        r += `1fr `;
      }
      return r;
  }
  
export const getGridItems = (columns, rows) => {
   let str = '', counter = 0;
   for(let r = 1; r<=rows; r++){
      for(let c = 1; c<=columns; c++){
        str += `
          .item-${counter}{
            grid-column-start: ${c};
            grid-column-end: ${c};
            grid-row-start: ${r};
            grid-row-end: ${r};
            border: 2px dashed black;
          }
        `
        counter++;
      }
    }
  
   return str;
  }